@import './variables';

// LOCATIONS: Intro
.locations {

	.select {
		height: fit-content;
	}

	.card {

		.intro {
			padding: 1.25rem 1.75rem;
		}

		.content .is-table .tr {
			padding: 10px 1.25rem 10px 1.75rem;
	  	}

		.content.editing {
			padding: 0.75rem;

			.is-table .tr {
				padding-left: 1rem;
			}
		}

		.card-footer {
			margin: 1.75rem;
		}
	}

	.card.location-intro {
		padding: 1.25rem 1.75rem;
	}

	.card-long.no-avatar article {
		gap: 0;
	}

	.card.comments .content {

		.note {
			padding: 0;
		}

		.note.pinned {
			padding: 1rem;
		}

		.add-note-btn button, .add-note .add-note-label {
			margin: 0.5rem 0;
		}

		.edit-note .card-footer {
			margin: 1rem 0;
		}

		.card-footer {
			margin: 0.5rem 1.75rem;
		}
	}
	
	.card.location-basic-info {

		button.error {
			margin-left: 20px;
		}

		.editing {

			.result-list {

				padding-left: 50px;
			}
			
			.tr {
				background-color: $colors-white;
			}

			.custom-label {
				display: flex;

				.inherit-checkbox {
					margin-left: auto;

					.items-start {
						align-items: center;

						div {
							margin-left: 0;
						}
					}
				}

			}
		}

    // displays grid layout of epic visit types
		.wrapper {
			display: flex;
			flex-direction: column;
			width: 60%;
			border-left: 1px solid $colors-gray-700;
			border-top: 1px solid $colors-gray-700;
			border-right: 1px solid $colors-gray-700;

			.labels {
				display: flex;
				border-bottom: 1px solid $colors-gray-700;
				font-weight: 700;

				.type-name {
					width: 45%;
					padding: 0 0 0 5px;					
				}

				.id {
					width: 23%;
					padding: 0 0 0 5px;
				}

				.open,
				.direct {
					width: 16%;
					padding: 0 0 0 5px;
				}

				.type-name,
				.id,
				.open {
					border-right: 1px solid $colors-gray-700;
				}
			}

			.epic-visit-types {
				display: flex;
				flex-direction: column;

				.values {
					display: flex;
					border-bottom: 1px solid $colors-gray-700;

					div{
						word-break: break-word;
					}

					.epic-type-name,
					.id,
					.allow-open-scheduling,
					.allow-direct-scheduling {
						display: flex;
						padding: 0 0 0 5px;
					}

					.epic-type-name {
						width: 45%;
					}
					.id {
						width: 23%;
					}

					.allow-open-scheduling,
					.allow-direct-scheduling {
						width: 16%;
					}

					.epic-type-name,
					.id,
					.allow-open-scheduling {
						border-right: 1px solid $colors-gray-700;
					}
				}
			}
		}

    .editing {
     .wrapper {
      display: inline-flex;
      margin-top: 10px;
			width: 100%;
     } 

     .wrap-epic-specialty {
        display: flex;
        flex-direction: row;
        align-items: center;

      .label {
        display: inline-flex;
      }

      .call-out {
        padding-left: 10px;
        display: inline-flex;
      }
     }
    }
	}

	.card.location-hours {

		button.error {
			margin-left: 35px;
		}

		.tr {
			background-color: $colors-white;
		}

		.schedule, .schedule-exceptions {
			width: 100%;
			.tr {
				padding-top: 5px;
				padding-bottom: 5px;
			}

			.viewing {

				.no-schedule {
					margin: 0 1.75rem;
				}

				.schedule-day {
					.tr {
						.td {
							&:nth-child(1) {
								width: 30%;
							}
						}
					}
				}
				
				.is-table {
					
					.tr {
						.td {
							justify-content: left;

							&:nth-child(2) {
								width: 20%;
							}

							&:nth-child(3) {
								justify-content: left;
							}
						}
					}
				}
			}

			.content.editing {
				padding: 0;
			}
		}

		.editing {

			.add-schedule-exception-btn {
				font-size: 14px;
				  margin: 1rem auto 0 1rem;
			}

			.schedule-exceptions {
				margin: 15px 0;

				.select {
					width: fit-content;
				}

				.schedule-exception-date {
					display: flex;
					flex-direction: row;
					align-items: flex-end;
				
					.select {
						height: 3rem;
						padding-top: 0;
						padding-bottom: 0;
						width: 110px;
						text-align: center;
					}

					label {
						margin-right: 20px;
					}
			
					.span {
						margin: 0 10px;
						font-size: 18px;
					}
					
					.delete-schedule-exception-btn {
						margin-left: auto;
					}
				}
			}

			.schedule-day {
				.tr {
					background-color: $colors-white;
					padding-left: 0;

					input {
						width: 80px;
						margin: 0 10px;
						height: 3rem;
					}

					.select {
						height: 3rem;
						padding-top: 0;
						padding-bottom: 0;
						width: 140px;
					}

					.td {
						display: flex;
						flex-direction: column;
						align-items: baseline;

						&:nth-child(1) {
							flex-direction: row;
							align-items: center;
							width: 30%;
						}

						.schedule-row {
							display: flex;
							flex-direction: row;
							align-items: center;

							.separator {
								width: 20px;
							}
						}
					}
				}
			}
		}
	}

	.card.location-scheduling {

		button.error {
			margin-left: 35px;
		}
		.editing {
			.tr {
				background-color: $colors-white;
				label {
					span {
						display: inherit;
					}
				}
			}
		}
	}

	.card.location-tags {
		.is-table .is-row {
			background-color: $colors-white;
			flex-direction: column;
			.td {
				justify-content: flex-start;
				width: 100%;
			}
		}
		.item-list {
			display: flex;
			flex-flow: wrap;
			.badge {
				background-color:$colors-primary-700;
				padding: 10px 15px;
				color: $colors-white;
				margin: 0 10px 10px 0;
				.button {
					display: flex;
					align-items: center;
					svg {
						margin-left: 5px;
					}
				}
			}
		}
		.flex.flex-col {
			flex-direction: column;
			margin-bottom: 10px;
		}
		.is-table .tr.is-col {
			background-color: $colors-white;
		}
	}

	.card.location-yext {
		.tr {
			background-color: $colors-white;
		}
		.yext-error, .yext-errors {
			padding: 0 1.75rem;
			color: $colors-danger;
		}
	}
}

// related places
.card.related-places {
  .content {
    padding: 0 $padding-default 10px 1.75rem;
    .wrapper {
      display: flex;
      flex-direction: row;
  
      .link {
        width: 75%;
      }

      .paragraph {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 25%;
        padding-left: 5px;
        font-size: $font-size-sm;
        font-size: $font-size-xs;
        text-align: right;
      }
    }
  }
}