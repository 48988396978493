@import './variables';

.header {
	width: 100%;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: center;
		color: $colors-gray-700;
	}

	li:not(:last-child) {
		margin-right: 48px;
	}

	img {
		position: absolute;
		left: 68px;
	}

	.environment {
		position: absolute;
		left: 200px;
		font-weight: bold;
		font-size: 32px;
		color: $colors-danger;
	}

	.main-nav a {
		font-weight: 600;
	}

	.main-nav,
	.sub-nav {
		height: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	li a:hover {
		border-bottom: 3px solid rgb(24 128 140 / 50%);
		padding-bottom: 4px;
	}

	li a.active {
		border-bottom: 3px solid $colors-primary-700;
		padding-bottom: 4px;
	}

	.sub-nav a.active {
		border-bottom: 3px solid rgb(24 128 140 / 50%);
	}

	/* HEADER BOTTOM */
	.sub-nav {
		background-color: $colors-primary-50;
	}
	.sub-nav-border {
		border: 1px solid $colors-danger;
	}
}
