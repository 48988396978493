@import './variables';

.side-bar-related {
  border: 1px solid $colors-gray-200;
  margin: $padding-default;
  width: 35%;
  padding: $padding-default;
  display: flex;
  flex-direction: column;

  .related-container {
    flex: 1;
    overflow: auto;
    margin-top: $padding-default;
    
    .related-list{
      max-height: 0;
      li {
        font-weight: 500;
      }
    }
  }
}
