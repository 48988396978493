@import './variables';

.home, .locations-home {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 50px;

	.home-top {
		width: 100%;
		display: flex;
		margin: 20px 0;

		.search-bar {
			display: flex;
			margin-top: 20px;
			flex-direction: row;
	
			input {
				border: 1px solid $colors-gray-200;
				border-right: 0;
				height: 60px;
				font-size: $font-size-sm;
				border-radius: 0.3125rem 0 0 0.3125rem;
				padding: 20px;
	
				&::placeholder {
					font-weight: 500;
				}
	
				&:hover {
					border-color: $colors-gray-700;
				}
	
				&:focus {
					outline: none;
					border-color: $colors-primary-700;
					border: 2px solid $colors-primary-700;
					border-right: 0;
					box-shadow: none;
				}
	
				@media #{$small} {
					width: 400px;
				}
			}
	
			button {
				padding: 0 1.25rem;
	
				&::after {
					border-radius: 0 0.3125rem 0.3125rem 0;
				}
			}
	
		}

		.add-new-provider-btn, .add-new-location-btn {
			margin: 0 0 0 auto;
			align-self: end;
		}
	}

	.card.add-new-provider, .card.add-new-location {
		width: 630px;
		align-self: baseline;
		margin-left: 0;
	}
}

.search {

	.results {
		border-width: 0 1px 1px;
		border-style: solid;
		border-color: #edefef;
		box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
		border-radius: 0 0 5px 5px;

		li {
			display: table;
			width: 100%;
		}

		a {
			width: 100%;
			display: table-cell;
			vertical-align: middle;
			height: 56px;
			padding-left: 20px;
			font-weight: 400;

			&:hover {
				background: $colors-gray-50;
			}
		}

		&.hide {
			display: none;
		}

		&.show {
			display: block;
		}
	}
}
