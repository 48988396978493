.breadcrumb {
	height: 64px;
	display: flex;
	align-items: center;
	margin-left: 68px;
	color: $colors-primary-700;

	a {
		color: $colors-primary-700;
	}
}
