@import './variables';

.main {
	width: 100%;
	display: flex;
	flex-direction: column;

	@media #{$medium} {
		width: $width-grid-9-12;
		display: inline-flex;
	}
}
