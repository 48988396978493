@import './variables';

.card {
	display: flex;
	border: 1px solid $colors-gray-100;
	border-radius: $border-radius-default;
	flex-direction: column;
	margin: 15px;

	span.required {
		color: $colors-required;
		margin-left: 3px;
	}

	span:hover {
		cursor: default;
	}

	.user-card {
		display: flex;
		flex-direction: column;

		@media #{$medium} {
			flex-direction: row;
		}

		.avatar,
		.information,
		.alert-message {
			display: flex;
			width: 100%;

			@media #{$medium} {
				display: inline-flex;
			}
		}

		// Avatar component's width is set to 25% on larger devices
		.information {
			flex-direction: column;

			@media #{$medium} {
				width: 50%;
				padding: $padding-default;
			}
		}

		.alert-message {
			@media #{$medium} {
				width: 25%;
				justify-content: flex-end;
			}
		}
	}

	.intro {
		display: flex;
		width: 100%;
		justify-content: space-between;
		padding: $padding-default;

		.header-2,
		.edit {
			display: inline-flex;
		}

		.header-2,
		.header-3 {
			margin-top: 0;
		}

		.edit {
			width: 15%;
			justify-content: flex-end;
			color: $colors-primary-default;
			align-items: center;

			.btn {
				display: flex;
				align-items: center;
			}

			.pencil {
				margin-right: 5px;
				color: $colors-primary-default;
			}
		}
	}

	.intro.editing {
		padding: 1.45rem $padding-lg;
	}

	.content {
		display: flex;
		width: 100%;
		flex-direction: column;

		// specific to not editing mode
		.is-table .tr {
			padding: 10px $padding-default 10px $padding-default;
		}

		.is-table.is-editing {
			padding: 0 1rem;
		}
	}
}

.card-footer {
	flex-direction: row;
	margin: $padding-default 2.25rem;

	button {
		&:nth-child(1) {
			width: 40%;
			margin-right: $padding-default;
		}

		&:nth-child(2) {
			color: $colors-primary-default;
		}
	}
}
