@import './variables';

// basic-info
.card.basic-info {
	.dob {
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		.select {
			text-align: center;
			padding-top: 0;
			padding-bottom: 0;
		}

		.span {
			margin: 0 10px;
			font-size: 18px;
		}
	}

	button.error {
		margin-left: 2rem;
	}

	.dob-error {
		justify-content: flex-start;
	}

	.crud .add-btn {
		color: $colors-primary-default;
	}
}

// availability
.card.availability {
	.search-care-partners {
		display: flex;
		align-items: center;
		padding: 10px 1.25rem 10px 1.25rem;

		.search-care-partners-input {
			width: 75%;
		}

		.search-care-partners-button {
			height: 52px;
			margin-top: 8px;
		}
		.after\:rounded::after {
			border-radius: 0 5px 5px 0;
		}
	}

	.select {
		padding-top: 0;
		padding-bottom: 0;
	}
}

// intro
.card.intro {
	padding: $padding-default;

	.card-footer {
		margin: $padding-default $padding-lg 0 0;
	}

	&.editing {
		padding: 1.45rem 2.25rem;
	}
}

// Biography
.card.biography {
	.content {
		padding: 0 $padding-default $padding-default $padding-default;

		&.editing {
			padding: 0 2.25rem;
		}
	}
}

// Profile Picture
.card.picture {
	.content {
		flex-direction: unset;
		padding: $padding-default;

		.edit-picture {
			width: 100%;
		}
	}
}

//video
.card.video {
	.content {
		padding: 0 $padding-default $padding-default $padding-default;
		&.editing {
			padding: 0 2.25rem;
		}
	}
}

// At a glance
.card.at-a-glance {
	.content {
		.tr {
			background-color: $colors-white;
		}

		.bold {
			font-weight: bold;
			margin-right: 5px;
		}

		.green-text {
			color: $colors-success;
		}

		.red-text {
			color: $colors-danger;
		}

		.fa-xmark {
			color: $colors-danger;
			margin: 0 5px;
		}

		.fa-check {
			color: $colors-success;
			margin: 0 5px;
		}

		.ineligibility-reasons {
			color: $colors-danger;
			border-color: $colors-danger;
			width: 100%;
			padding: 15px;
			display: flex;

			.information {
				fill: $colors-danger;
				margin-right: 10px;
				font-size: 18px;
			}
		}

		.select {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

// Notes
.card.comments {
	.content {
		padding: 1px;
		.tr {
			background-color: $colors-white;
		}

		.border-top {
			border-top: 1px solid $colors-gray-200;
		}

		.note {
			padding: 10px;

			.note-body {
				margin-top: 10px;
			}

			.pinned-top {
				font-weight: bold;
			}

			.link-divider {
				margin: 10px;
			}

			.note-last-modified {
				font-style: italic;
				margin: 10px 0;
			}
		}

		.add-note-btn {
			width: 100%;

			button {
				margin: 10px;
			}
		}

		.edit-note,
		.add-note {
			width: 100%;

			.add-note-label {
				margin: 10px;
			}

			.note-input-textarea {
				height: 130px;
			}
		}

		.pinned {
			width: 100%;
			border: 1px solid $colors-gray-200;
			background-color: $colors-gray-100;
		}
	}
}

// Audit Log
.card.audit-log {
	.content {
		.bold {
			font-weight: bold;
			margin-right: 5px;
		}

		.last-updated {
			margin: 0 0 30px 20px;
			font-size: 14px;
		}

		.tr {
			padding: 5px 20px;
			align-items: baseline;
			flex-direction: column;

			.logs-details,
			li {
				margin: 10px;
				word-wrap: anywhere;
			}
		}
	}
}

// Add new provider and location
.card.add-new-provider,
.card.add-new-location {
	.content {
		.tr {
			background-color: $colors-white;
			padding-top: 0;

			&:nth-child(1) {
				padding-bottom: 0;
			}

			.is-col {
				width: 50%;
				&:nth-child(1) {
					padding-left: 0;
				}
			}

			.td {
				width: 50%;

				align-items: baseline;
				&:nth-child(2) {
					margin-left: 40px;
				}
			}

			label {
				width: 100%;
			}
		}

		.select {
			height: fit-content;
			margin: 15px 0;
		}

		.add-new-textfield-location {
			width: 100%;
		}

		.add-new-textfield {
			width: 275px;
		}
	}

	.card-footer {
		margin-left: 20px;
	}
}

// Areas of focus
.card.areas-of-focus {
	.content {
		padding: 0 $padding-default $padding-default $padding-default;

		&.editing {
			padding: 10px 2.25rem;
		}
		.checkbox-list-container {
			columns: 2;
			div {
				width: 100%;
			}
		}
	}
}

// Insurance
.card.insurance {
	.content {
		padding: 0 $padding-default $padding-default $padding-default;

		&.editing {
			padding: 10px 2.25rem;
		}
	}
	.insurance-crud {
		margin-bottom: 15px;
	}
}

// Specialty
.card.specialty {
	.intro.editing {
		padding: 1.45rem $padding-lg 0 $padding-lg;
	}
	.content {
		h6 {
			padding: 1.45rem $padding-default;
		}
		.list {
			padding: 10px $padding-default 1.45rem $padding-default;

			.query-input {
				display: flex;
				width: 100%;
				align-items: center;
				padding-left: 15px;

				label {
					@media #{$medium} {
						width: 300px;
					}
				}

				.close-btn-specialty-input {
					margin-left: 20px;
				}
			}

			.item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #edefef;
				margin: 0 15px;

				.input-container {
					margin: 10px 0;

					@media #{$medium} {
						display: inline-flex;
						flex-direction: column;
					}

					.editable {
						position: relative;
						z-index: 2;
					}

					.properties-title {
						display: flex;
						flex-direction: column;
						margin-bottom: 15px;

						h6 {
							padding: 0;
						}
					}

					.properties-wrapper {
						margin: 5px 0;

						.property {
							padding-right: 10px;
						}
					}
				}
			}
		}

		.add-btn {
			color: $colors-primary-default;
			text-align: left;
			padding-left: 35px;
		}

		.is-table {
			.tr {
				&:nth-child(even) {
					background-color: $colors-gray-100;
				}
				&:nth-child(odd) {
					background-color: $colors-white;
				}
			}
		}
	}
}

// Provider places
.card.locations {
	button.error {
		margin-left: 35px;
	}

	hr.divider {
		margin: 10px 2px;
	}
	.provider-place {
		&.odd {
			background-color: $colors-gray-100;
		}

		.custom-label {
			display: flex;

			.delete-btn {
				margin-left: auto;

				button {
					height: 30px;
				}

				.items-start {
					align-items: center;
				}
			}
		}

		.tr.is-row {
			background-color: inherit;

			.td {
				word-wrap: anywhere;
				justify-content: left;
			}
		}

		.delete-place-btn {
			align-items: flex-end;
		}

		.schedule {
			width: 100%;
			display: flex;
  			align-items: center;

			.tr {
				padding: 0;
			}

			.viewing {
				.is-table {
					.tr {
						.td {
							justify-content: left;
						}
					}
				}
			}

			.editing {
				.tr {
					input {
						width: 80px;
						margin: 0 10px;
						height: 3rem;
					}

					.select {
						height: 3rem;
						padding-top: 0;
						padding-bottom: 0;
						width: 140px;
					}

					.td {
						display: flex;
						flex-direction: column;
						align-items: baseline;

						&:nth-child(1) {
							flex-direction: row;
							margin: 10px;
						}

						.schedule-row {
							display: flex;
							flex-direction: row;
							align-items: center;

							.separator {
								width: 20px;
							}
						}
					}
				}
			}
		}
	}
}

// special recognitions
.card.special-recognitions {
	.content {
		padding: 0 $padding-default $padding-default $padding-default;

		.paragraph {
			width: 50%;
			display: inline-flex;
		}

		&.editing {
			padding: 0 2.25rem;
			flex-direction: column;

			.add-btn {
				color: $colors-primary-default;
			}
		}

		.list {
			margin-bottom: 30px;
			.item {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				.input-container {
					display: inline-flex;

					.number-of-recognitions {
						margin-top: 30px;
						display: flex;
					}

					.recognition-amount {
						margin-left: 1rem;
					}
				}
			}
		}
	}
}
// Affiliations
.card.affiliations,
.card.insurance {
	.is-table .is-row .td:nth-child(2) {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		width: 50%;
	}
	.content.editing {
		padding: 10px 2.25rem;
		button {
			color: $colors-primary-700;
		}
		.hospital-affiliations {
			margin-bottom: $padding-default;

			.error {
				padding: 0;
			}
		}
	}
	.hos-selects,
	.ins-selects,
	.medical-group-crud {
		margin-bottom: 15px;
	}
	.hosp-item-container,
	.ins-item-container,
	.med-item-container {
		display: flex;
		align-items: center;
		.hos-item,
		.ins-item {
			margin-right: 10px;
			width: 500px;
		}

		.hos-crud-btns,
		.ins-crud-btns,
		.med-crud-btns {
			display: flex;
		}
	}

	.add-btn {
		display: flex;
	}
}
// Educations
.card.educations {
	.card-footer {
		margin-left: 2rem;
	}

	button.error {
		margin-left: 2rem;
	}

	.content {
		.td {
			&:nth-child(2) {
				width: 60%;
			}
		}
	}

	.content.editing {
		padding-left: 0.75rem;

		.tr {
			background-color: $colors-white;
		}

		.autocomplete-input .autocomplete-textfield {
			margin-top: 0px;
		}

		.custom-cell {
			label {
				margin-top: 5px;
			}

			.select {
				padding-top: 0;
				padding-bottom: 0;
			}

			&:nth-child(2) {
				margin-left: 10px;
				width: 65%;
			}

			&:nth-child(3) {
				padding-left: 5px;
				display: flex;
				align-items: flex-end;

				button {
					height: 50px;
					min-width: 2rem;
					padding: 0 1rem;
				}
			}
		}
	}
}


// YEXT
.card.yext {

	.yext-error, .yext-errors {
		white-space: break-spaces;
		padding: 0 2.25rem;
		color: $colors-danger;
	}
}