@import './variables';

.insurance-lists {
  .list-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $padding-lg 0;
  }

  h2{
    padding-top: 50px;
  }
  .search .search-bar {
    margin: 0;
    display: flex;
  }

  .edit-item {
    .edit-row {
      width: 100%;
      flex-direction: column !important;
    }
    .checkbox-list-container {
      columns: 2;
      margin-top: 8px;

      div {
        width: 100%;
      }
    }
  }
}