a {
	text-decoration: none;
	font-family: $font-family;
	font-size: $font-size-sm; /* 14px */
	font-weight: 500;
	line-height: $line-height-6; /* 24px */
	color: $colors-gray-700;
	border-radius: 0 !important;
}

input[type='checkbox'] {
	margin-right: 10px;
	margin-top: 3px;
	padding-top: 5px;
	font-family: $font-family;
	font-size: $font-size-sm; /* 14px */
	text-decoration: none;
	font-weight: 500;
	line-height: $line-height-6; /* 24px */
	color: $colors-gray-700;

	&:hover {
		border-radius: 0;
	}
}
