// REPRESENTATION styles of Table, tr, and td html elements as individual components
@import './variables';

.is-table {
	display: flex;
	flex-direction: column;

	.tr {
		display: flex;
		flex-direction: row;
		font-size: 14px;

		&:nth-child(odd) {
			background-color: $colors-gray-100;
		}
	}

	.is-row {
		flex-direction: row;
	}

	.is-col {
		flex-direction: column;
	}

	.td {
		align-items: center;
		word-wrap: anywhere;

		&:nth-child(2) {
			justify-content: center;
		}

		&:nth-child(3) {
			justify-content: flex-end;
		}
	}

	.not-full {
		display: inline-flex;

		&:nth-child(1),
		&:nth-child(2) {
			justify-content: flex-start;
			width: 40%;
		}
	}

	.full {
		display: flex;
		width: 100%;
	}

	// component specific to table for Cards during editing
	input[type='text'],
	input[type='number'],
	select {
		height: 3.2rem;
		width: 100%;
	}

	.flex.flex-col {
		flex-direction: column;

		.text-danger {
			display: flex;
			width: 100%;
			color: $colors-danger;
		}
	}

	.error-msg {
		color: $colors-danger;
	}

	.information {
		margin-right: 100px;
	}
}

.is-table.is-editing .tr {
	&:nth-child(odd) {
		background-color: transparent;
	}

	.td {
		margin-bottom: 5px;
	}
}

.set-to-arial {
	font-family: Arial, sans-serif;
}
