@import './variables';

.autocomplete-input {

    .autocomplete-textfield {
        margin-top: 8px;
    }

    .search-results{
        width: 100%;
        padding: 0;
        border: 1px solid $colors-gray-200;
        border-top: none;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        max-height: 400px;
        overflow-y: auto;
        background-color: $colors-white;
        

        button {
            margin: 0;
            display: flex;
            flex-direction: row;
            padding: 10px 0 10px 16px;
            text-align: left;
            
            &:hover {
                background-color: $colors-gray-100;
            }
        }
    }
}