@import './variables';

.app {
	font-family: $font-family;
}

.container {
	margin: 0 auto;
	background-color: $colors-white;
}

::after,
::before {
	box-sizing: border-box;
}

@media #{$small} {
	.container {
		width: 606px;
	}
}

@media #{$medium} {
	.container {
		width: 767px;
	}
}

@media #{$large} {
	.container {
		width: 991px;
	}
}

@media #{$x-large} {
	.container {
		width: 1199px;
	}
}

.loading,
.error,
.success {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: $padding-default;
}

.success {
	color: $colors-success;

	.paragraph {
		margin-left: 10px;
	}
}

.error {
	color: $colors-danger;

	.paragraph {
		color: $colors-danger;
		margin-right: 10px;
		white-space: break-spaces;
	}
}
