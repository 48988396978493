@import './variables';

// basic crud styles
.crud-edit {
	display: flex;
	flex-direction: column;

	.list {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;

		.item {
			display: flex;
			flex-direction: row;
			margin: 5px 0;
			align-items: center;

			.input-container,
			.inside-btns {
				display: inline-flex;
				width: auto;
			}

			button {
				color: $colors-primary-default;
			}

			.input-container {
				margin-right: 10px;

				select {
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}
	}

	.add-btn {
		display: flex;
		color: $colors-primary-default;
	}
}
