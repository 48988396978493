@import './variables';

.provider, .location {
	display: flex;
	flex-direction: column;
	position: relative;

	@media #{$medium} {
		flex-direction: row;
	}

	@media #{$x-large} {
		&.container {
		   width: 1350px;
		}
	}
	.alert-bar {
		width: 100%;
	}
}