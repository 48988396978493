@import './variables';

.avatar {
	.image {
		vertical-align: middle;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&:hover {
		.hover {
			border-radius: 50%;
			position: absolute;
			z-index: 1;
			background-color: rgb(0 0 0 / 50%);
			width: 100px;
			height: 100px;
			background-image: url('../assets/add_camera.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 50%;
		}
	}
}
