@import './variables';

.prompt {
	position: fixed;
	z-index: $z-index-2 + 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(0 0 0 / 20%);

	.confirm {
		background-color: $colors-white;
		padding: 30px;
		display: flex;
		flex-direction: column;

		.message {
			margin-bottom: 30px;
		}

		button {
			&:nth-child(2) {
				margin-bottom: $padding-default;
			}
		}
	}
}

.layer {
	position: absolute;
	z-index: $z-index-2 + 1;
	background-color: transparent;
	width: 100%;
}
