// break points
$small: '(min-width:607px)';
$medium: '(min-width: 768px)';
$large: '(min-width: 992px)';
$x-large: '(min-width: 1200px)';

// borderRadius
$border-radius-default: 0.3125rem; // 5px if base font-size is 16px
$border-radius-none: 0;
$border-radius-full: 9999px;

// borderWidth
$border-width-default: 1px;
$border-width-0: 0;
$border-width-2: 2px;
$border-width-3: 3px;

// boxShadow
$box-shadow-default: 2px 2px 6px rgb(0 0 0 / 10%);

// padding
$padding-default: 1.25rem;
$padding-sm: 1.5rem;
$padding-lg: 2rem;
$padding-xl: 4.375rem;
$padding2xl: 4.375rem;

// COLORS
$colors-inherit: inherit;
$colors-transparent: transparent;
$colors-black: #000;
$colors-white: #fff;

// primary
$colors-primary-50: #ebf4f6;
$colors-primary-700: #18808c;
$colors-primary-800: #01697d;
$colors-primary-default: #18808c;

// secondary
$colors-secondary-50: #fffbf5;
$colors-secondary-200: #fed99b;
$colors-secondary-default: #fed99b;

//   gray
$colors-gray-50: #fafcfc;
$colors-gray-100: #edefef;
$colors-gray-200: #cfd8dc;
$colors-gray-700: #42484d;
$colors-success: #45a57c;

// other
$colors-required: red;
$colors-danger: #ba4534;

// fontSize
$font-size-xs: 0.8125rem;
$font-size-sm: 0.875rem;
$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.375rem;
$font-size-2xl: 1.5rem;
$font-size-3xl: 1.75rem;
$font-size-4xl: 2.125rem;
$font-size-5xl: 2.5rem;
$font-size-6xl: 3rem;

// fontFamily
$font-family: 'Montserrat', 'ui-sans-serif', 'system-ui', '-apple-system',
	'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial',
	'"Noto Sans"', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"',
	'"Segoe UI Symbol"', '"Noto Color Emoji"';

// transitionTimingFunction
$transition-timing-function-default: cubic-bezier(0.4, 0, 0.2, 1);
$transition-timing-function-in: cubic-bezier(0.4, 0, 1, 1);
$transition-timing-function-out: cubic-bezier(0, 0, 0.2, 1);
$transition-timing-function-in-out: cubic-bezier(0.4, 0, 0.2, 1);
$transition-timing-function-bounce-in: cubic-bezier(0.345, 0.115, 0.135, 1.42);

//   dropShadow
$drop-shadow-sm-bottom: 0 2px 2px rgb(0 0 0 / 5%);

//   lineHeight
// Ensuring we have line height consistency for the utility classes
$line-height-3: 1.0625rem;
$line-height-6: 1.5rem;
$line-height-7: 1.75rem;
$line-height-8: 1.875rem;
$line-height-9: 2rem;
$line-height-10: 2.25rem;
$line-height-11: 2.625rem;
$line-height-12: 4rem;

//   spacing
// Add custom spacing hacks for that pixel perfect render!
$spacing-4-25: 1.125rem; // 18px
$spacing-4-5: 1.1875rem; // 19px; eg: large button with outline
$spacing-15: 3.75rem; // 60px
$spacing-25: 6.25rem; // 100px

//   width: {
$width-grid-2: calc((100% - (1.25rem * 1)) / 2);
$width-grid-3: calc((100% - (1.25rem * 2)) / 3);
$width-grid-4: calc((100% - (1.25rem * 3)) / 4);
$width-grid-5: calc((100% - (1.25rem * 4)) / 5);
$width-grid-3-12: calc((100% - (1.25rem * 1)) / (12 / 3));
$width-grid-5-12: calc((100% - (1.25rem * 1)) / (12 / 5));
$width-grid-7-12: calc((100% - (1.25rem * 1)) / (12 / 7));
$width-grid-9-12: calc((100% - (1.25rem * 1)) / (12 / 9));

//   maxHeight: {
$max-height-10-12: 83.3333%;

//   zIndex
$z-index-1: 1;
$z-index-2: 2;
