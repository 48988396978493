@import './variables';

.results-btn-wrapper {
	display: flex;
	flex-direction: column;
	z-index: $z-index-2;
	position: absolute;
	width: 100%;
	background-color: $colors-white;
	padding: 10px 20px;
	border: 1px solid $colors-gray-100;
	overflow: auto;
	max-height: 400px;

	@media #{$medium} {
		width: 50%;
	}

	.results-btn {
		display: inline-flex;
		padding: 5px 0;

		&:hover {
			background-color: $colors-gray-100;
		}
	}
}
