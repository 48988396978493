@import './variables';

.datagrid-home {
	width: 90%;

	.animation {
		width: 100%;
	}

	td {
		padding: 10px;
		vertical-align: middle;
		text-align: center;
	}

	input.column-filter {
		height: 2.5rem;
		margin-top: 8px;
	}

	.column-header {
		height: 80px;
		padding: 10px 15px;
	}

	.columnid-name, .columnid-name_formatted {
		min-width: 200px;
	}

	.column-filter-select {
		height: 2.5rem;
		padding-top: 0;
		padding-bottom: 0;
		width: fit-content;
		max-width: 300px;
	}
}

.datagrid,
.datagrid-home {

	.icon-chevron {
		color: $colors-primary-default;
		font-size: 14px;
	}

	.message {
		margin: 15px auto;
	}

	.filter-add {
		width: 100%;
		display: flex;

		.clear-filter-btn {
			margin-top: 15px;
		}

		.add-new-btn {
			margin: 15px 0 0 auto;
		}
	}

	.edit-row {
		width: 100%;
		padding: 10px;
		margin: 15px 0;

		span.required {
			color: $colors-required;
			margin-left: 3px;
		}

		.tr {
			background: none;
			margin: 5px;
		}

		.delete-btn {
			&::after {
				background-color: $colors-danger;
			}
		}

		.edit-item-select,
		.edit-item-map-select,
		.edit-item-path-select {
			height: fit-content;
		}

		p.helptext {
			font-style: italic;
			margin: 10px 0;
		}

		.separator {
			font-weight: bold;
			font-size: larger;
			margin: 7px 6px 7px 0px;
		}

		.item-list {
			display: flex;
			flex-flow: wrap;

			div.badge-container {
				height: fit-content;
				display: flex;
				flex-flow: wrap;

			}

			.badge {
				background-color: $colors-primary-default;
				padding: 10px 15px;
				color: $colors-white;
				margin: 0 10px 10px 0;

        .button {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            margin-right: 15px;
          }
        }
			}
		}

		.error-item {
			color: red;
		}

		.edit-footer {
			flex-direction: row;
			width: 100%;

			button {
				width: 25%;
				margin: 10px 20px;
			}
		}
	}

	.p-1 {
		padding: 2px 5px;
	}

	.gap-1 {
		margin: 3px;
	}

	.page-input {
		width: 80px;
		margin: 5px 0;
	}

	.filter-all-input {
		width: 200px;
		margin: 15px 10px 15px 0;
		font-weight: normal;
	}

	.pagesize-select {
		width: 150px;
	}

	.item-selector {
		margin-bottom: 20px;
	}

	table {
		width: 100%;
		margin: 0;

		.header {
			background-color: $colors-primary-50;
		}

		th,
		td {
			padding: 10px $padding-default 10px $padding-default;
			border: 1px solid $colors-gray-200;
		}

		td.cell-url, td.cell-image_url {
			word-break: break-all;
		}

		td.cell-edit {
			padding: 5px;
			vertical-align: middle;
			text-align: center;
			button {
				padding: 15px;
			}
		}

		tr.selected-row {
			background-color: $colors-secondary-200;
		}

		.even {
			background-color: $colors-gray-50;
		}

		.odd {
			background-color: $colors-gray-100;
		}
	}

	.edit-item {
		.card.edit-row {
			display:flex;
			flex-direction: row;
			justify-content: space-between;

			.edit-row-left {
				width: 65%;
			}
		}
	}

	tr:has(.a-child) {
		background-color: $colors-gray-100;
	}
}
